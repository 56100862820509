.top-menu-container {
    background-color: transparent;
    height: 5rem;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    padding: 0 2rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    max-width: 100vw !important;
    user-select: none;
    transition: all .3s;
    z-index: 2001;

    background-color: #222 !important;
}

.top-menu-container .logo {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    color: white;
    cursor: default;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 4rem;
}

.top-menu-container .right-section {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.top-menu-container.active {
    background-color: #222;
    transition: all .3s;
}

.top-menu-container .btns-bar {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

/* .top-menu-container img {
    max-height: ;
} */