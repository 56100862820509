.footer-block {
    background-color: #333;
    color: #999;
    font-size: 1rem;
    font-weight: 200;
    width: 100%;
    text-align: center;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem 10%;
    box-sizing: border-box;
}