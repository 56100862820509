.titled-input {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: .25rem;
}

.titled-input input {
    width: 100%;
    /* height: 1.75rem; */
    border-radius: .5rem;
    outline: none;
    background-color: transparent;
    border: 1px solid #fcfcfc;
    padding: .75rem 1rem;
    color: #fcfcfc;
    font-size: 1rem;
    box-sizing: border-box;
}

.titled-input .title {
    user-select: none;
    font-weight: 400;
}

.titled-input input:focus {
    outline: 2px solid var(--primary-color);
}