.card {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    z-index: 2000;
    background-color: #fcfcfc;
    min-width: 12rem;
    max-width: 17rem;
    border-radius: .75rem .75rem 0 0;
    padding: 2rem;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-bottom: 3px solid var(--primary-color);
}

.card .ico-sample {
    width: 10rem;
    height: 10rem;
    color: var(--primary-color);
    font-size: 4rem;
    font-weight: 600;
    text-align: center;
}

.card .title {
    font-size: 2rem;
    font-weight: 600;
    color: #222;
    text-align: center;
    margin: 0;
    height: 5rem;
}

.card .description {
    font-size: 1rem;
    font-weight: 200;
    color: #444;
    text-align: center;
}