.present-block {
    width: 100%;
    height: 100vh;
    background-color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1.5rem;
    user-select: none;
    z-index: -1;
    overflow: hidden;

}

.present-block .title-1 {
    display: none;
    color: var(--primary-color);
    font-size: 1.5rem;
    max-width: 65%;
    font-weight: 400;
    margin: 0;
    padding: 0;
    cursor: default;
    text-align: center;
    z-index: 1;
}

.present-block .subtitle-1 {
    color: #fcfcfc;
    max-width: 65%;
    font-size: 2.5rem;
    text-align: center;
    font-weight: 600;
    margin: 0;
    padding: 0;
    cursor: default;
    z-index: 1;
}

.present-block .bg {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    
    /* filter: blur(2px); */
    opacity: 0.7;
}

#myVideo {
    min-height: 100%;
    /* width: 100%; */
  }
  
  /* Add some content at the bottom of the video/page */
  .content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }