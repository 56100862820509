.services-block-container {
    width: 100%;
    min-height: calc(100vh - 5rem);
    box-sizing: border-box;
    padding: 2rem 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    scroll-margin-top: 5rem;
}