body {
    margin: 0;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

html {
    scroll-behavior: smooth;
}

* {

    --primary-color: #688fb0;
    --primary-hover-color: #688fb088;
}
@media (max-width: 768px) {
    .top-menu-container {
        background-color: #222 !important;
    }
    .top-menu-container .btns-bar {
        display: none !important; 
    }

    .top-menu-container .side-menu-container {
        display: block;
    }

    .present-block .title-1 {
        max-width: 80%;
    }

    .present-block .subtitle-1 {
        max-width: 80%;
        font-size: 1.5rem !important;
    }
    .contact-form .row {
        flex-direction: column !important;
        row-gap: 2rem;
    }
    .contact-form .send-form-btn {
        width: 100% !important;
    }
    .equipment-block-container {
        padding: 1.5rem 10% !important;
        gap: 1rem !important;
    }
    .services-block-container .titled-description p {
        margin-top: -.5rem !important;
    }
    .cards-row {
        margin-top: 1rem !important;
    }
    .roll-up-btn {
        display: fixed !important;
    }
}

@media (max-width: 992px) {
    .top-menu-container .contact-us-btn {
        display: none;
    }
    .maps-block-container .cards-row {
        flex-direction: column !important;
    }

    .card {
        /* width: 100% !important; */
        max-width: 100% !important;
    }
    .descripted-img-container {
        flex-direction: column !important;
    }
    .descripted-img-container .img-container {
        min-height: 50vw;
    }
    .descripted-img-container .titled-description .title {
        text-align: center;
    }
    .equipment-block-container .block-title {
        font-size: 2rem !important;
    }
}
