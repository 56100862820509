.roll-up-btn {
    width: 3rem;
    height: 3rem;
    background-color: var(--primary-color);
    position: fixed;
    right: 1rem;
    bottom: 5rem;
    border-radius: 1.5rem;
    border: none;
    outline: none;
    z-index: 9999999999;
    display: none;
}