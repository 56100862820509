.contact-from-container {
    width: 100%;
    min-height: calc(100vh - 5rem);
    padding: 5rem 10%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #333;
    color: #fcfcfc;
    scroll-margin-top: 5rem;
}

.contact-from-container .contact-form {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    box-sizing: border-box;
    gap: 3rem;
}

.contact-form .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    column-gap: 5rem;
}

.contact-form .col {
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 2rem;
}

.contact-form .send-form-btn {
    width: 15rem;
    align-self: center;
    height: 2.5rem;
    background-color: var(--primary-color);
    border: none;
    outline: none;
    border-radius: .5rem;
    color: #fcfcfc;
    cursor: pointer;
    font-size: .85rem;
    transition: all .3s;
}

.contact-form .send-form-btn:hover {
    background-color: var(--primary-hover-color);
    transition: all .3s;
}

.contact-from-container .feedback-title {
    font-size: 2.5rem;
    font-weight: 600;
    padding-bottom: 3rem;
    margin: 0;
}

.contact-from-container .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.contact-from-container .success-send {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    display: none;
}

.contact-from-container .success-send label {
    font-size: 1.5rem;
    font-weight: 400;
}