.side-menu-container {
    display: none;
}

.side-menu-container .side-menu-toggler {
    width: 3rem;
    height: 3rem;
    border: none;
    outline: none;
    border-radius: .5rem;
    cursor: pointer;
    background-color: var(--primary-color);
}

.side-menu-container .side-menu {
    background-color: #222;
    position: absolute;
    top: 5rem;
    right: 0;
    /* transform: translateX(-100%); */
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 20rem;
    /* animation: slide 1s forward; */
}

.side-anim-enter {
    transform: translateX(100%);
}

.side-anim-enter-active {
    transform: translateX(0%);
    transition: transform .3s;
}

.side-anim-exit {
    transform: translateX(0%);
}

.side-anim-exit-active {
    transform: translateX(100%);
    transition: transform .3s;
}