.nav-btn {
    min-width: 5rem;
    max-width: 10rem;
    height: 3rem;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: .8rem;
    font-weight: 400;
    color: #fcfcfc;
    background-color: transparent;
    padding: 0 1rem;
    transition: all .3s;
    white-space: nowrap;
}

.nav-btn.selected {
    color: var(--primary-color);
}

.nav-btn:hover {
    color: var(--primary-color);
    transition: all .3s;
}

.nav-link-anchor:link {
    color: var(--primary-color);
}