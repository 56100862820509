.equipment-block-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5rem 10%;
    box-sizing: border-box;
    min-height: 100vh;
    background-color: #333;
    row-gap: 5rem;
    scroll-margin-top: 5rem;
}

.equipment-block-container .block-title {
    font-size: 2.5rem;
    font-weight: 600;
    color: #fcfcfc;
    text-align: center;
    margin: 0;
}