.maps-block-container {
    width: 100%;
    flex: 1;
    min-height: calc(100vh + 10rem);
    flex-direction: column;
    position: relative;
}

.ymaps-map {
    -ms-touch-action: auto !important;
    touch-action: auto !important;
}

.maps-block-container .cards-row {
    bottom: 10rem;
    margin-top: -10rem;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    max-width: 100%;
    padding: 0 10%;
    padding-bottom: 3rem;
    box-sizing: border-box;
    justify-content: space-between;
    gap: 2rem;
}