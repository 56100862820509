.titled-textarea {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: .25rem;
}

.titled-textarea .title {
    user-select: none;
    font-weight: 400;
}

.titled-textarea textarea {
    border: 1px solid #fcfcfc;
    border-radius: .5rem;
    background-color: rgba(255, 255, 255, 0.1);
    font-size: 1rem;
    color: #fcfcfc;
    padding: .5rem 1rem;
    box-sizing: border-box;
    resize: none;
}

.titled-textarea textarea:focus {
    outline: 2px solid var(--primary-color);
}