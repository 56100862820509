.change-lang-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    bottom: 2rem;
    left: 2rem;
    width: 6rem;
    height: 2rem;
    border-radius: .25rem;
    overflow: hidden;
    z-index: 3000;
}

.change-lang-btn button {
    flex: 1;
    padding: 0;
    outline: none;
    border: none;
    height: 100%;
    background-color: #eee;
    color: var(--primary-color);
    font-weight: 600;
    cursor: pointer;
    font-size: .8rem;
}

.change-lang-btn button.active {
    background-color: var(--primary-color);
    color: #fff;
}

.change-lang-btn button:hover {
    opacity: .9;
}