.contact-us-btn {
    border: none;
    outline: none;
    background-color: var(--primary-color);
    color: #fcfcfc;
    padding: .5rem 2rem;
    font-size: .75rem;
    height: 2.5rem;
    border-radius: .5rem;
    transition: all .3s;
}

.contact-us-btn:hover {
    cursor: pointer;
    background-color: var(--primary-hover-color);
    transition: all .3s;
}