.descripted-img-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    column-gap: 5rem;
    min-height: 30rem;
    box-sizing: border-box;
}

.descripted-img-container .img-container {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex: 1;
    border-radius: .5rem;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 8px 24px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.descripted-img-container .titled-description {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.descripted-img-container .titled-description.dark {
    color: #fcfcfc;
}

.descripted-img-container .titled-description .title {
    font-size: 2rem;
    font-weight: 600;
    /* text-align: justify; */
}

.descripted-img-container .titled-description .description {
    font-size: 1.25rem;
    font-weight: 300;
    text-align: justify;
}